// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-care-and-treatment-jsx": () => import("/Users/helios/hhfd-nz/src/pages/care-and-treatment.jsx" /* webpackChunkName: "component---src-pages-care-and-treatment-jsx" */),
  "component---src-pages-early-diagnosis-jsx": () => import("/Users/helios/hhfd-nz/src/pages/early-diagnosis.jsx" /* webpackChunkName: "component---src-pages-early-diagnosis-jsx" */),
  "component---src-pages-healthy-hearts-circulatory-system-jsx": () => import("/Users/helios/hhfd-nz/src/pages/healthy-hearts/circulatory-system.jsx" /* webpackChunkName: "component---src-pages-healthy-hearts-circulatory-system-jsx" */),
  "component---src-pages-healthy-hearts-diet-jsx": () => import("/Users/helios/hhfd-nz/src/pages/healthy-hearts/diet.jsx" /* webpackChunkName: "component---src-pages-healthy-hearts-diet-jsx" */),
  "component---src-pages-healthy-hearts-exercise-jsx": () => import("/Users/helios/hhfd-nz/src/pages/healthy-hearts/exercise.jsx" /* webpackChunkName: "component---src-pages-healthy-hearts-exercise-jsx" */),
  "component---src-pages-healthy-hearts-heart-facts-jsx": () => import("/Users/helios/hhfd-nz/src/pages/healthy-hearts/heart-facts.jsx" /* webpackChunkName: "component---src-pages-healthy-hearts-heart-facts-jsx" */),
  "component---src-pages-healthy-hearts-index-jsx": () => import("/Users/helios/hhfd-nz/src/pages/healthy-hearts/index.jsx" /* webpackChunkName: "component---src-pages-healthy-hearts-index-jsx" */),
  "component---src-pages-healthy-hearts-routine-check-ups-jsx": () => import("/Users/helios/hhfd-nz/src/pages/healthy-hearts/routine-check-ups.jsx" /* webpackChunkName: "component---src-pages-healthy-hearts-routine-check-ups-jsx" */),
  "component---src-pages-healthy-hearts-your-dogs-health-jsx": () => import("/Users/helios/hhfd-nz/src/pages/healthy-hearts/your-dogs-health.jsx" /* webpackChunkName: "component---src-pages-healthy-hearts-your-dogs-health-jsx" */),
  "component---src-pages-heart-disease-dilated-cardiomyopathy-jsx": () => import("/Users/helios/hhfd-nz/src/pages/heart-disease/dilated-cardiomyopathy.jsx" /* webpackChunkName: "component---src-pages-heart-disease-dilated-cardiomyopathy-jsx" */),
  "component---src-pages-heart-disease-how-is-it-diagnosed-jsx": () => import("/Users/helios/hhfd-nz/src/pages/heart-disease/how-is-it-diagnosed.jsx" /* webpackChunkName: "component---src-pages-heart-disease-how-is-it-diagnosed-jsx" */),
  "component---src-pages-heart-disease-index-jsx": () => import("/Users/helios/hhfd-nz/src/pages/heart-disease/index.jsx" /* webpackChunkName: "component---src-pages-heart-disease-index-jsx" */),
  "component---src-pages-heart-disease-mitral-valve-disease-jsx": () => import("/Users/helios/hhfd-nz/src/pages/heart-disease/mitral-valve-disease.jsx" /* webpackChunkName: "component---src-pages-heart-disease-mitral-valve-disease-jsx" */),
  "component---src-pages-heart-disease-stages-of-heart-disease-jsx": () => import("/Users/helios/hhfd-nz/src/pages/heart-disease/stages-of-heart-disease.jsx" /* webpackChunkName: "component---src-pages-heart-disease-stages-of-heart-disease-jsx" */),
  "component---src-pages-heart-disease-understanding-heart-disease-jsx": () => import("/Users/helios/hhfd-nz/src/pages/heart-disease/understanding-heart-disease.jsx" /* webpackChunkName: "component---src-pages-heart-disease-understanding-heart-disease-jsx" */),
  "component---src-pages-heart-disease-what-are-the-signs-jsx": () => import("/Users/helios/hhfd-nz/src/pages/heart-disease/what-are-the-signs.jsx" /* webpackChunkName: "component---src-pages-heart-disease-what-are-the-signs-jsx" */),
  "component---src-pages-index-jsx": () => import("/Users/helios/hhfd-nz/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-measure-health-jsx": () => import("/Users/helios/hhfd-nz/src/pages/measure-health.jsx" /* webpackChunkName: "component---src-pages-measure-health-jsx" */),
  "component---src-pages-sitemap-jsx": () => import("/Users/helios/hhfd-nz/src/pages/sitemap.jsx" /* webpackChunkName: "component---src-pages-sitemap-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/helios/hhfd-nz/.cache/data.json")

